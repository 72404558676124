if (!Element.prototype.toggleAttribute) {
	Element.prototype.toggleAttribute = function(name, force) {
		if(force !== void 0) force = !!force;
	
		if (this.hasAttribute(name)) {
			if (force) return true;
	
			this.removeAttribute(name);
			return false;
		}
		if (force === false) return false;
	
		this.setAttribute(name, "");
		return true;
	};
}

if (!Element.prototype.matches) {
	Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}
  
if (!Element.prototype.closest) {
	Element.prototype.closest = function(s) {
		var el = this;
  
		do {
			if (el.matches(s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}