const tipsList = {
	tips: document.querySelector('.tips_list'),
	tipClick(e) {
		const clickedTip = e.target.closest('.tip_title');
		if (clickedTip) {
			clickedTip.closest('li').toggleAttribute('open');
		}
	},
	init() { 
		this.tips.addEventListener('click', e => this.tipClick(e));
		setTimeout(() => {
			document.querySelector('.tip_title').click();
		}, 500);
	},
};

export const initTipsList = () => tipsList.init();