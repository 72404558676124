/*global google*/
import { AutomatitCarousel } from './automatit_carousel.m';
import {getMapsHelper} from './util/mapsHelper.js';

const facCaro = {
	caro: document.querySelector('#facility_top_caro'),
	init() {
		AutomatitCarousel({
			element: this.caro,
			showDots: true,
			useChevrons: false,
			imagesAsBackgrounds: true,
			images: [
				{src: "/dist/images/content/img_homepage_slide.jpg", alt: ""},
				{src: "/dist/images/content/img_homepage_slide2.jpg", alt: ""},
				{src: "/dist/images/content/img_homepage_slide3.jpg", alt: ""},
				{src: "/dist/images/content/img_homepage_slide4.jpg", alt: ""},
			]
		});
	}
};

const facTop = {
	facTopClick() {
		document.querySelector('#toggle_fac_info').toggleAttribute('active');
		document.querySelector('#facility_top_info_caro').toggleAttribute('active');
	},
	init() {
		document.querySelector('#toggle_fac_info').addEventListener('click', () => this.facTopClick());
	}
};

const facilityFilters = {
	filters: document.querySelectorAll('#facility_filters button'),
	rows: document.querySelectorAll('.rates_row'),
	noUnits: document.querySelector('#no_units'),
	filterClick(e) {
		const clickedButton = e.target.closest('button');
		if (clickedButton) {
			this.filters.forEach(filter => filter.removeAttribute('active'));
			clickedButton.setAttribute('active', '');

			let numUnits = 0;

			this.rows.forEach(row => {
				row.setAttribute('hide', '');
				if (clickedButton.getAttribute('size') === row.getAttribute('size') || clickedButton.getAttribute('size') === 'all') {
					row.removeAttribute('hide');
					numUnits++;
				}
			});

			if(numUnits) {
				this.noUnits.style.display = 'none';
			} else {
				this.noUnits.style.display = 'block';
			}
		}
	},
	init() {
		document.querySelector('#facility_filters').addEventListener('click', e => this.filterClick(e));
	}
};




const facMap = {
	init: async function() {
		const mapsHelper = getMapsHelper();

		await mapsHelper.ready();
		const theMap = mapsHelper.createMap({
			useRichmarker: true,
			element: document.querySelector('#facility_map'),
			locationElementSelector: '#facility_map',
			markerReducer: (el) => {
				return {
					lat: el.getAttribute('lat'),
					lng: el.getAttribute('lng'),
					content: `<span class="map_pin"><img src="/dist/images/icons/ic_green_mappin.svg" alt=""/></span>`
				};
			}
		});

		google.maps.event.addListenerOnce(theMap, 'idle', () => {
			theMap.setZoom(17);
			document.querySelector('#toggle_fac_map').addEventListener('click', () => {
				document.querySelector('#facility_map').toggleAttribute('active');
				document.querySelector('#toggle_fac_map').toggleAttribute('active');
			});
		});
	}
};

export const initFacMap = () => facMap.init();

export const initFacCaro = () => facCaro.init();
export const initFacTop = () => facTop.init();
export const initFacilityFilters = () => facilityFilters.init();