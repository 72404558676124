/* global google */
import {
	getMapsHelper
} from './util/mapsHelper';

const map = {
	init(){
		const mapHelper = getMapsHelper();
		mapHelper.ready()
			.then(() => {
				const theMap = mapHelper.createMap({
					element: document.querySelector('.map_info'),
					locationElementSelector: '.map_info',
					useRichmarker: true,
					markerReducer: el => {
						return {
							lat: el.getAttribute('lat'),
							lng: el.getAttribute('lng'),
							content: `
                            <a class="map_pin" href="${el.getAttribute('link')}">
                                <img src="/dist/images/icons/ic_green_mappin.svg" alt="Visit facility">
                            </a>
                            `
						};
					}
				});

				google.maps.event.addListenerOnce(theMap, 'idle', () => {
					theMap.setZoom(15);
				});
			});
	}
};

export const initMap = () => map.init();