import { AutomatitCarousel } from './automatit_carousel.m';

const homeCaro = {
	caro: document.querySelector('#home_caro'),
	init() {
		AutomatitCarousel({
			element: this.caro,
			showDots: true,
			useChevrons: false,
			imagesAsBackgrounds: true,
			images: [
				{src: "/dist/images/content/img_homepage_slide.jpg", alt: ""},
				{src: "/dist/images/content/img_homepage_slide2.jpg", alt: ""},
				{src: "/dist/images/content/img_homepage_slide3.jpg", alt: ""},
				{src: "/dist/images/content/img_homepage_slide4.jpg", alt: ""},
			]
		});
	}
};

export const initHomeCaro = () => homeCaro.init();