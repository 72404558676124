import './polyfills.js';

import { header } from './header.js';
import { initHomeCaro } from './home';
import { initFacCaro, initFacTop, initFacMap, initFacilityFilters } from './facility';
import { contactSubmit } from './contact.js';
import { initTipsList } from './tips.js';
import { initMap } from './map.js';

header.init();

if(document.querySelector('#home_caro')) {
	initHomeCaro();
}

if(document.querySelector('#facility_top_caro')) {
	initFacCaro();
}

if(document.querySelector('#toggle_fac_info')) {
	initFacTop();
}

if(document.querySelector('#facility_map')) {
	initFacMap();
}

if(document.querySelector('#facility_filters')) {
	initFacilityFilters();
}

if (document.getElementById('contact_form')) {
	contactSubmit();
}

if (document.querySelector('#tips')) {
	initTipsList();
}

if (document.querySelector('.map_info')) {
	initMap();
}