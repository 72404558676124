export const header = (function(){
	let my = {},
		mobileToggle = document.getElementById('mobile_toggle');

	function _toggleMobileMenu(){
		mobileToggle.toggleAttribute('active');
		document.getElementById('site_header_links').toggleAttribute('active');
	}

	function _assignListeners(){
		mobileToggle.addEventListener('click', _toggleMobileMenu);
	}

	my.init = function(){
		_assignListeners();
	};

	return my;
})();